@import "src/components/scss/vars";

.root {
  background: linear-gradient(0deg, #ffffff, #ffffff), #ecf8fb;
  box-shadow: 10px 10px 64px rgba(217, 230, 233, 0.5);
  border-radius: 10px;
}

.data {
  padding: 0 21px;
}
.categoryOneLine {
  display: flex;
  &__title {
    margin-right: 15px;
  }
  & svg {
    margin-right: 5px;
  }
}

.image img {
  border-radius: 10px 10px 0 0;
  width: 100%;
}
.wrapperImg {
  margin-right: 5px;
}
.category {
  padding: 15px;
  font-size: 14px;
  line-height: 171.7%;
  &__title {
    color: rgba(9, 53, 79, 1);
  }
  &__value {
    color: rgba(9, 53, 79, 0.7);
  }
  &__valueSvg {
    display: flex;
    & svg {
      margin-right: 5px;
    }
  }
  &Outer:after {
    display: block;
    content: " ";
    background-image: url("../../../../../assets/icons/after.svg");
    width: 100%;
    height: 2px;
  }
}
.text {
  max-width: 100%;
}

.btn {
  padding: 21px 0 26px;
  display: flex;
  justify-content: center;
  width: 100%;
}
