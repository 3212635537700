@import "src/components/scss/vars";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
  margin: 0 10px;
  // @include mobile {
  //   margin: 0 auto;
  // }
}

.language {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $accentTextColor;
  font-size: 1.2rem;

  text-transform: uppercase;

  cursor: pointer;
  padding: 5px;
  &.active {
    &:before {
      position: absolute;
      top: 0;
      left: auto;
      right: auto;
      content: "";
      display: block;
      height: 2px;
      width: 40px;
      background: $accentTextColor;
    }
    @media screen and (max-width: $md) {
      &:before {
        display: none;
      }
    }
  }
}
