@import "src/components/scss/vars";
@import "src/components/scss/mixin";

.root {
  font-size: inherit;
  color: #09354f;
  @include innerHtmlStyles();
  ul {
    margin-left: 0;
    padding-left: 0;
    li {
      margin-left: 0;
      padding-left: 0;
      font-size: 20px;
      color: rgba(9, 53, 79, 1);
      // &::marker {
      //   content: url("../../../assets/icons/ganeral/checkBlue.svg");
      // }
    }
  }
  ol {
    margin-left: 0;
    padding-left: 0;
    li {
      margin-left: 0;
      padding-left: 0;
    }
  }
  p {
    padding-top: 15px;
    &:first-child {
      padding-top: 0;
    }
  }

  strong {
    font-weight: 600;
  }
  @media screen and (max-width: $smPlus) {
    p {
      font-size: 14px;
    }
    ul {
      li {
        font-size: 14px;
      }
    }
  }
}
