.root {
  display: inline-flex;
  align-items: flex-start;
  &:hover:not([disabled]) {
    .image {
      box-shadow: 7px 9px 30px 0 rgba(80, 214, 224, 0.4);
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.text {
  font-size: 18px;
  line-height: 171.7%;
  color: #09354f;
  margin-left: 19px;
}
.wrapperImage {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
// .image {
//   border-radius: 50%;
//   width: 100%;
//   object-fit: cover;
// }
.image {
  width: 35px;
  height: 35px;
  border-radius: 60%;

  object-fit: cover;
}
