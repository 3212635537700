*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: GothamPro, sans-serif;
}

a {
  text-decoration: none;
}

body {
  position: relative;
}

#root {
  overflow: hidden;
}

img {
  object-fit: cover;
}
input {
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  // max-width: 500px;
  img {
    max-width: 350px;
    display: block;
    width: 100%;
  }
}

body,
html {
  height: 100%;
  scroll-behavior: smooth;
}
iframe {
  width: 100%;
  height: 100%;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

@font-face {
  font-family: "TragicMarker";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Tragic Marker 400.otf");
  font-display: swap;
}

@font-face {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/GothamPro.ttf");
  font-display: swap;
}

@font-face {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 900;
  src: url("/assets/fonts/GothamPro.ttf");
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/GothamPro-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: bold;
  src: url("/assets/fonts/GothamPro-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/GothamPro-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "JakobCTT";
  src: url("/assets/fonts/Jakobctt.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
