.text {
  max-width: 100%;
  display: flex;
  // justify-content: space-between;
  margin-top: 100px;
  column-gap: 15px;
}
.memberPopUp {
  p {
    padding: 15px 0;
  }
}
.info:after {
  display: block;
  content: "";
  position: absolute;
  left: -200px;
  bottom: 300px;
  width: 400px;
  height: 400px;
  background: #defbff;
  z-index: -1;
  filter: blur(220px);
  border-radius: 480.5px;
}
.wrapperSilder {
  margin-top: 53px;
  img {
    border-radius: 10px;
  }
}
.name {
  margin-right: 50px;
}

@import "src/components/scss/vars";

@media screen and (max-width: $md) {
  .wrapperSilder {
    margin-top: 25px;
  }
  .text {
    flex-direction: column;
    margin-top: 75px;
  }
  .memberPopUp {
    margin-top: 50px;
  }
}

@media screen and (max-width: $smPlus) {
  .text {
    margin-top: 35px;
  }
}
