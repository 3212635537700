@mixin btn($background, $color, $hover, $active, $disabled) {
  color: $color;
  font-family: GothamPro, sans-serif;
  text-transform: capitalize;
  background: $background;
  &:hover {
    background: $hover;
  }
  &:active {
    background: $active;
  }
  &.disabled {
    background: $disabled;
  }
}

.root {
  padding: 0 69px;
  height: 60px;
  border: none;

  border-radius: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  cursor: pointer;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btnLearnMore {
  @include btn(
    (linear-gradient(57.25deg, #b1e0ec -18.76%, #ecf8fb 234.49%)),
    (#09354f),
    (
      linear-gradient(0deg, #b9f2ff, #b9f2ff),
      linear-gradient(57.25deg, #b1e0ec -18.76%, #ecf8fb 234.49%)
    ),
    (
      linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
      linear-gradient(57.25deg, #b1e0ec -18.76%, #ecf8fb 234.49%)
    ),
    (
      linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)),
      linear-gradient(57.25deg, #b1e0ec -18.76%, #ecf8fb 234.49%)
    )
  );
}

.btnApplicationForm {
  @include btn(
    (radial-gradient(131.06% 130% at 54.47% 130%, #09354f 0%, #1a6480 100%)),
    (#ffffff),
    (
      radial-gradient(131.06% 130% at 54.47% 130%, #09354f 0%, #2c98b0 100%),
      radial-gradient(131.06% 130% at 54.47% 130%, #09354f 0%, #1a6480 100%)
    ),
    (
      linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
      radial-gradient(131.06% 130% at 54.47% 130%, #09354f 0%, #1a6480 100%)
    ),
    (
      linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
      radial-gradient(131.06% 130% at 54.47% 130%, #09354f 0%, #1a6480 100%)
    )
  );
}

.btnShowMore {
  @include btn(
    (#e7f6f9),
    (#032940),
    (linear-gradient(0deg, #d0f7ff, #d0f7ff), #e7f6f9),
    (linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #e7f6f9),
    (
      linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
      #e7f6f9
    )
  );
}
.active {
  background: linear-gradient(57.25deg, #b1e0ec -18.76%, #ecf8fb 234.49%),
    linear-gradient(
      243.48deg,
      rgba(40, 137, 158, 0.7) -18.01%,
      rgba(9, 53, 79, 0.7) 102.63%
    ),
    linear-gradient(0deg, #ffffff, #ffffff), #ecf8fb;
}

.small {
  font-size: 14px;
  padding: 0 26px;
  height: 50px;
}
