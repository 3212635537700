@import "src/components/scss/vars";
.root {
  width: 100vw;
  overflow-y: auto;
  height: 100%;
  position: fixed;
  z-index: 10;
}
.primaryBackground {
  background: rgba(115, 115, 115, 0.8);
}
.secondaryBackground {
  background: #06161f;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.close {
  z-index: 999;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.container {
  overflow: hidden;
  position: relative;
  width: calc(100% - 60px);
  margin: 38px auto;
  background: #ffffff;
  border-radius: 10px;
  z-index: 10;
}

/* width */
.scroll::-webkit-scrollbar {
  margin: 10px 0;
  width: 7px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: $mdMinus) {
  .fullSizeOnMobile.container {
    border-radius: 0;
    width: 100%;
    height: 100%;
    margin-top: 0;
  }
  .fullSizeOnMobile.background {
    background: #ffffff;
    display: flex;
    align-items: center;
  }

  .container {
    width: calc(100% - 20px);
  }
}
