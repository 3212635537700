@import "src/components/scss/mixin";

.root {
  width: 100%;
  display: inline-flex;
  flex-direction: column;

  & label {
    font-size: 14px;
    line-height: 227.7%;
    color: rgba(9, 53, 79, 0.5);
  }

  & input {
    box-sizing: border-box;
    height: 46px;
    background: rgba(180, 210, 222, 0.2);
    outline: none;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    line-height: 227.7%;
    color: #000000;
    padding: 5px 16px;

    &:focus {
      background: white;
      outline: 1px solid #2c98b0;
    }
  }
}
.required {
  @include star-required();
}

.error {
  @include error-fildes();
}

.wrapperInput {
  position: relative;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
}

.showHide {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 17px;
  &:hover {
    cursor: pointer;
  }
}
