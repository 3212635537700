.rootOuter {
  display: inline-block;
  position: relative;
}

.title {
  display: inline-block;
  font-size: 22px;
  text-transform: capitalize;
  color: #032940;
  z-index: 1;
  padding: 0 20px;
  & svg {
    margin-left: 10px;
  }
}
.root {
  display: inline-flex;
  align-items: center;
  height: 62px;
  cursor: pointer;
  &:before {
    position: absolute;
    display: inline-block;
    content: "";
    background: linear-gradient(0deg, #c1f1f8, #c1f1f8),
      linear-gradient(0deg, #ffffff, #ffffff), #ecf8fb;
    border-radius: 10px;
    width: 47px;
    height: 62px;
    transition: 0.5s all ease;
  }
}

.root.active:hover:before {
  width: 100%;
}
.root.active:active:before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
    linear-gradient(0deg, #c1f1f8, #c1f1f8),
    linear-gradient(0deg, #ffffff, #ffffff), #ecf8fb;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

@import "src/components/scss/vars";

@media screen and (max-width: $md) {
  .root {
    // background: linear-gradient(57.25deg, #b1e0ec -18.76%, #ecf8fb 234.49%),
    //   linear-gradient(
    //     243.48deg,
    //     rgba(40, 137, 158, 0.7) -18.01%,
    //     rgba(9, 53, 79, 0.7) 102.63%
    //   ),
    //   linear-gradient(0deg, #ffffff, #ffffff), #ecf8fb;
    border-radius: 10px;
    &:before {
      display: none;
    }
  }
  .desktopBtn {
    margin-right: 180px;
    background: linear-gradient(0deg, #c1f1f8, #c1f1f8),
      linear-gradient(0deg, #ffffff, #ffffff), #ecf8fb;
  }
  .backgroundDark {
    background: linear-gradient(57.25deg, #b1e0ec -18.76%, #ecf8fb 234.49%),
      linear-gradient(
        243.48deg,
        rgba(40, 137, 158, 0.7) -18.01%,
        rgba(9, 53, 79, 0.7) 102.63%
      ),
      linear-gradient(0deg, #ffffff, #ffffff), #ecf8fb;
  }
}
@media screen and (max-width: $md) {
  .root {
    width: 100%;
  }
}

@media screen and (max-width: $mobile) {
  .title {
    font-size: 18px;
  }
}
