.root {
  // max-width: 250px;
  &Img {
    max-width: 250px;
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }
  &Title {
    margin-top: 35px;
    font-size: 26px;
    line-height: 116.19%;
    color: #032940;
  }
  &Desc {
    margin: 22px 0 35px;
    font-size: 18px;
    line-height: 171.7%;
    color: #09354f;
    max-width: 250px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;   
  }
  
}
.item{
  margin: 0 0 60px 0;
}
.moreInformation{
  color:#6d92a2;
  display: inline-block;
  font-size: 18px;
  text-transform: capitalize;
  cursor: pointer; 
}

.moreInformation:hover {
  color: #2bbfff;
} .moreInformation:active {
  color: #d6d6d6;
} 
// .slideEven {
//   margin-top: 50px;
// }
