$xl: "1440px";
$lg: "1024px";
$md: "768px";
$mobile: "600px";
$mdMinus: "576px";
$smPlus: "464px";
$sm: "320px";

$smCustom: "375px";

$accentTextColor: #0d5b7a;
$black: #000000;
$lightDarkTextColor: #09354f;
$darkBlackTextColor: #032940;
