@import "src/components/scss/vars";

.title {
  font-size: 14px;
  line-height: 145.7%;
  color: rgba(9, 53, 79, 0.7);
  margin-top: 21px;
}
.value {
  font-size: 14px;
  line-height: 145.7%;
  color: rgba(9, 53, 79, 1);
}

.section {
  &__about {
    display: flex;
    &Item {
      margin-left: 35px;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__skills {
    max-width: 606px;
    display: flex;
    flex-wrap: wrap;
    &Item {
      margin: 0 13px 10px 0;
    }
  }

  &__items {
    padding: 21px 0;
  }

  &:after {
    display: block;
    content: " ";
    background-image: url("../../../../../assets/icons/after2.svg");
    width: 100%;
    height: 2px;
  }
  &:last-child:after {
    display: none;
  }
}

.languages {
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
}
.language {
  margin: 10px 0;
  width: 155px;
  display: flex;

  align-items: flex-start;
  &:first-child {
    margin-left: 0;
  }
  &Svg {
    margin-top: 5px;
    margin-right: 5px;
    width: 18px;
    height: 14px;
  }
  &Title {
    font-size: 16px;
    line-height: 171.7%;
    color: #09354f;
  }
  &Level {
    font-size: 14px;
    color: #09354f;
    opacity: 0.7;
  }
}
