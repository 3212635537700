@import "src/components/scss/vars";

.main {
  padding-bottom: 100px;
  min-height: 100vh;
}
.root {
  display: flex;

  column-gap: 50px;
}

.preview {
  min-width: 300px;
  width: 300px;
}

.main {
  width: 100%;
}

.backToProfile {
  cursor: pointer;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    radial-gradient(131.06% 130% at 54.47% 130%, #09354f 0%, #1a6480 100%);
  opacity: 0.9;
  border-radius: 19px;
  margin-bottom: 57px;
  display: flex;
  align-items: center;
  width: fit-content;

  padding-right: 15px;
  color: #09354f;
  & span {
    margin-left: 11px;
  }

  &Adaptive {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
    .img {
      cursor: pointer;
      position: absolute;
      left: 0;

      img {
        width: 27px;
        height: 10px;
      }
    }
    span {
      font-size: 22px;
      line-height: 156.7%;
      text-align: center;
      color: #09354f;
    }
  }
}

@media screen and (max-width: $md) {
  .main {
    padding-bottom: 40px;
  }
  .root {
    align-items: center;
    flex-direction: column;
  }
  .preview {
    margin-bottom: 50px;
    min-width: 1px;
    width: 100%;
  }
}
