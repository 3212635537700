.root {
  width: 100%;
  display: inline-flex;
  flex-direction: column;

  & label {
    font-size: 14px;
    line-height: 227.7%;
    color: rgba(9, 53, 79, 0.5);
  }

  & input {
    box-sizing: border-box;
    height: 46px;
    background: rgba(180, 210, 222, 0.2);
    outline: none;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    line-height: 227.7%;
    color: #000000;
    padding: 5px 16px;
    &.fill {
      background: linear-gradient(0deg, #ffffff, #ffffff),
        rgba(180, 210, 222, 0.3);
      outline: 1px solid #2c98b0;
    }
  }
}
.required {
  font-size: 18px;
}

.error {
  padding-top: 10px;
  text-align: center;
  color: rgb(185, 40, 40);
}
