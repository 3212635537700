@import "src/components/scss/vars";
@import "src/components/scss/mixin";

.buttons-root {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  max-width: 225px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  border-radius: 10px 10px 0 0;

  &.left {
    left: 0;
  }

  &.hideNumOfSlide {
    background: transparent;
    width: 67 * 2px;
  }

  & .slide-num {
    width: 100px;
    font-size: 20px;
    line-height: 116.19%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.custom-slider {
  @include user-select();
  line-height: 0;
  display: block;
  position: relative;
  border-radius: 10px;
  height: 100%;
  // -webkit-box-shadow: 0px 69px 54px -65px rgba(0, 0, 0, 0.24);
  // -moz-box-shadow: 0px 69px 54px -65px rgba(0, 0, 0, 0.24);
  // box-shadow: 0px 69px 54px -65px rgba(0, 0, 0, 0.24);
  &:after {
    @include shadowUnder("bottom", -20px, "left", 50%, 75%, 10%, #000, 0.9);
  }
  & img {
    display: block;
    width: 100%;
    height: 100%;
  }

  & .buttons {
    display: flex;
  }

  & .button {
    background: #ffffff;
    width: 67px;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.left {
      border-radius: 10px 0 0 0;
    }

    &.right {
      border-radius: 0 10px 0 0;
    }

    &:last-child svg {
      transform: rotate(180deg);
    }

    & svg {
      fill: #000000;
    }

    &:hover svg {
      fill: #bababa;
    }
  }
}

.slider {
  position: relative;
  z-index: 1111;
}

.slick-dots li button:after {
  color: antiquewhite;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list, .slick-slider {
  position: relative;
  display: block;
}

.slick-slider .slick-list, .slick-slider .slick-track {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.slick-slide img {
  display: block;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-track:after, .slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.custom-dots {
  margin-top: 55px;
}
.custom-dots:after, .slick-active .custom-dots:after {
  display: block;
  width: 9px;
  height: 9px;
  content: "";
  background: #cedfe4;
  border-radius: 100%;
}
.slick-active {
  & .custom-dots:after {
    display: block;
    width: 9px;
    height: 9px;
    content: "";
    background: #367790;
    border-radius: 100%;
  }
}
.slick-dots li, .slick-dots li button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}

@media screen and (max-width: $smPlus) {
  .custom-slider {
    & .button {
      width: 48px;
      height: 48px;
    }
  }
}
