.root {
  width: 100%;
  & textarea {
    width: 100%;
    height: 150px;
    resize: none;
  }

  & label {
    font-size: 14px;
    line-height: 227.7%;
    color: rgba(9, 53, 79, 0.5);
  }

  & textarea {
    background: rgba(180, 210, 222, 0.2);
    outline: 1px solid rgba(180, 210, 222, 0.2);
    border: none;
    border-radius: 10px;
    font-size: 14px;
    box-sizing: border-box;
    color: #000000;
    padding: 5px 16px;
    &.fill {
      background: linear-gradient(0deg, #ffffff, #ffffff),
        rgba(180, 210, 222, 0.3);
      outline: 1px solid #2c98b0;
    }
  }
}
.required {
  font-size: 18px;
}

.error {
  padding-top: 10px;
  text-align: center;
  color: rgb(185, 40, 40);
}
