@import "src/components/scss/vars";

.root {
  font-size: 14px;
  text-transform: capitalize;
  color: #09354f;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & span {
    margin-left: 6px;
  }
}
.navigaionProfileItem {
  display: inline-flex;
  align-items: center;
  margin-right: 30px;
  padding-left: 30px;
  font-size: 14px;
  line-height: 13px;
  text-transform: capitalize;

  color: #09354f;
  border-left: 1px solid #e8f4f6;
  &:first-child {
    border: none;
    padding-left: 0;
  }
}

.svg {
  fill: #09354f;
}

.active {
  cursor: pointer;
  &:hover {
    color: #2c98b0;
  }
  &:active {
    color: #09354f;
  }

  &:hover {
    .svg {
      fill: #2c98b0;
    }
  }
}
.disabled {
  opacity: 0.75;
  // cursor: not-allowed;
}
.navigaionProfile {
  display: inline-flex;
  align-items: center;
}

@media screen and (max-width: $md) {
  .navigaionProfileItem {
    margin-right: 10px;
    padding-left: 10px;
  }
}
