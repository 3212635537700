.root{
  display: flex;
}

.arrow{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 53px;
  height: 53px;
  background: #ECF8FB;
  border-radius: 10px;
  cursor: pointer;
  &:last-child{
    margin-left: 8px;
  }
}
.prev{
  transform: rotate(180deg);
}


.active{
  & .arrow:hover{
    &{
      background: linear-gradient(0deg, #D0F7FF, #D0F7FF), #ECF8FB;
    }
  }


}


.disabled{
  opacity: .6;
  & .arrow{
    background: none;
  }
}