@import "src/components/scss/vars";
.root {
  display: flex;
  align-items: flex-start;
  line-height: 31px;
  font-size: 18px;
  color: rgba(9, 53, 79, 0.8);
}

.text {
  margin-left: 10px;
}

.mainText {
  font-size: 20px;
  color: rgba(9, 53, 79, 1);
}

@media screen and (max-width: $smPlus) {
  .root {
    font-size: 14px;
  }
}
