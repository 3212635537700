@import "src/components/scss/vars";

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 70px;
  color: $lightDarkTextColor;
  padding: 300px 0;
  text-align: center;
}

.rootFontOne {
  font-family: "TragicMarker", sans-serif;
}
.rootFontTwo {
  font-family: "JakobCTT", sans-serif;
}

@media screen and (max-width: $smPlus) {
  .root {
    font-size: 60px;
    padding: 150px 20px;
  }
}
.groupBtn {
  display: flex;
  justify-content: center;
}
.tryAgain {
  margin-right: 20px;
}
