@import "src/components/scss/vars";
.root {
  padding: 38px 32px;
  max-width: 996px;
  margin: auto;
  position: relative;
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: $smPlus) {
  .root {
    padding: 38px 20px;
  }
}
