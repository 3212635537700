.root {
  display: inline-flex;
  align-items: center;
  padding: 0 13px;
  height: 30px;
  background: #e7f6f9;
  border-radius: 10px;
  font-size: 14px;
  text-transform: capitalize;
  color: #09354f;
}

.primary {
  background: #ffffff;
  &.active {
    background: #ffffff !important;
  }
}

.close {
  margin-left: 10px;
}
.secondary {
}

.active {
  &:hover {
    background: linear-gradient(0deg, #d0f7ff, #d0f7ff), #e7f6f9;
  }
  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
      #e7f6f9;
  }
  .close {
    cursor: pointer;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
