.base {
  background: #09354F;
  cursor: pointer;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.disabled {
  opacity: .6;
  cursor: not-allowed;
}

.root {
  &:hover {
    background: linear-gradient(0deg, rgba(44, 152, 176, 0.5), rgba(44, 152, 176, 0.5)), #09354F;
  }

  &:active {
    background: #000000;
  }
}

.arrowReverse {
  transform: rotate(180deg);
}

