@import "src/components/scss/vars";

.dimploma {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 171.7%;
  color: #09354f;
  &__text {
    margin-left: 5px;
    text-transform: capitalize;
  }
}

.root {
  padding: 40px 0;
}
.section {
  &__data {
    padding: 22px 0;

    // display: flex;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: minmax(350px, 1fr) minmax(130px, 130px) minmax(
        150px,
        150px
      );
    justify-content: space-between;
  }

  &__left {
    max-width: 650px;
  }

  &__right {
    // min-width: 140px;
    // width: 140px;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-end;
    padding-left: 30px;
  }

  &:after {
    display: block;
    content: " ";
    background-image: url("../../../assets/icons/after2.svg");
    width: 100%;
    height: 2px;
  }
  &:last-child:after {
    display: none;
  }
}

.left {
  &__top {
    &Title {
      max-width: 425px;
      font-size: 20px !important;
    }
    &Value {
      font-size: 18px !important;
    }
  }
}
.section__left {
  & .item {
    display: flex;
    margin: 9px 0;
  }
}
.title {
  font-size: 14px;
  line-height: 171.7%;
  color: #09354f;
}
.value {
  font-size: 14px;
  line-height: 171.7%;
  color: #09354f;
  opacity: 0.7;
  padding-right: 5px;
}
.valueRight {
  font-size: 14px;
  line-height: 171.7%;
  color: #09354f;
}
.date {
  margin: 0 0 10px;
}
.location {
  margin: 0 0 15px;
}
.howlong {
  margin: 2px 0;
}

.howlongAdaptive {
  display: none;
}

@media screen and (max-width: $md) {
  .dimploma {
    justify-content: flex-start;
  }
  .section {
    &__data {
      grid-template-columns: 1fr;
    }
    &__right {
      display: none;
    }
  }
  .howlongAdaptive {
    display: block;
  }
}
