.root {
  display: inline-block;
  font-size: 18px;
  text-transform: capitalize;
  color: #435760;
}
.active {
  cursor: pointer;
  &:hover {
    color: #57b9e2;
  }
  &:active {
    color: rgba(0, 0, 0, 0.3);
  }
}
.disable {
  cursor: not-allowed;
  opacity: 0.6;
}
