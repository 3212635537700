@import "src/components/scss/vars";
.rootFontOne {
  font-family: "TragicMarker", sans-serif;
}
.rootFontTwo {
  font-family: "JakobCTT", sans-serif;
}
.title {
  font-size: 105px;
  margin-bottom: 15px;

  color: #09354f;
  &.noUnderline {
    margin-bottom: 0;
  }
}
@media screen and (max-width: $lg) {
  .title {
    font-size: 75px;
  }
  .lgFontSize120 {
    font-size: 120px;
  }
}
@media screen and (max-width: $md) {
  .lgFontSize120 {
    font-size: 93px;
  }
}

@media screen and (max-width: 470px) {
  .title {
    font-size: 60px;
  }
}

@media screen and (max-width: $sm) {
  .title {
    font-size: 45px;
  }
}
