@mixin shadow(
  $horizontal,
  $horizontalValue,
  $vertical,
  $verticalValue,
  $width,
  $height
) {
  position: absolute;
  #{$horizontal}: $horizontalValue;
  #{$vertical}: $verticalValue;
  display: block;
  content: "";
  width: $width;
  height: $height;
  background: rgb(0, 201, 255);
  filter: blur(220px);
  border-radius: 500px;
  z-index: -100;
}

@mixin shadowUnder(
  $horizontal,
  $horizontalValue,
  $vertical,
  $verticalValue,
  $width,
  $height,
  $color,
  $opacity
) {
  position: absolute;
  #{$horizontal}: $horizontalValue;
  #{$vertical}: $verticalValue;
  // display: block;
  transform: translateX(-$verticalValue);
  content: "";
  width: $width;
  // width: 100%;
  height: $height;
  background: #26565949;
  filter: blur(23px);
  border-radius: 500px;
  z-index: -1;
  opacity: $opacity;
}

@mixin text-overflow($lines) {
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin star-required() {
  font-size: 18px;
}

@mixin error-fildes() {
  padding-top: 10px;
  text-align: center;
  color: rgb(185, 40, 40);
}

@mixin error-select() {
  border: 1px solid rgb(185, 40, 40);
}

@mixin user-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin innerHtmlStyles() {
  p {
    i {
      font-style: italic,
    }
    em {font-style: italic}
  }

  p {
    strong {
      font-weight: 450;
    }
  }
  h3 {
    margin: 20px 0;
    font-size: 24px;
    line-height: 116.19%;
    font-weight: 500;
  }
  ul {
    margin-left: 20px;
    li {
      padding-bottom: 20px;
      font-size: 18px;
      line-height: 171.7%;
      list-style: disc;
      // &::before {
      //   content: url("../../assets/icons/ganeral/checkBlue.svg");
      //   padding-right: 10px;
      // }
      &:last-child {
        padding-bottom: 0;
      }
    }
    margin-bottom: 30px;
  }
  blockquote {
    position: relative;
    margin: 100px auto;
    width: calc(100% - 100px);
    font-weight: 300;
    font-size: 28px;
    line-height: 142%;
    color: #09354f;
    &::before {
      content: url("../../assets/icons/ganeral/quoteTop.svg");
      position: absolute;
      top: -35px;
      left: -55px;
      color: black;
    }
    &::after {
      content: url("../../assets/icons/ganeral/quoteBottom.svg");
      position: absolute;
      bottom: -30px;
      right: 0;
      color: black;
    }
  }
  ol {
    margin-left: 20px;
    padding-bottom: 20px;
    li {
      padding-bottom: 20px;
      font-size: 18px;
      line-height: 171.7%;
      padding-left: 10px;
      // list-style: unset;
      // list-style-type: unset;
      &::before {
        content: url("../../assets/icons/ganeral/checkBlue.svg");
        padding-right: 10px;
      }
      // &::marker {
      //   list-style-type: unset;
      //   list-style: none;
      //   content: none;
      //   padding: 0;
      //   margin: 0;
      // }
      &:last-child {
        padding-bottom: 0;
      }
      strong {
        font-weight: 450;
      }
    }
  }
}
