@import "src/components/scss/vars";

.root {
  max-width: 1172px;
  margin: 0 auto;
}

@media screen and (max-width: $xl) {
  .root {
    max-width: 1172px;
    padding: 0 30px;
  }
}

@media screen and (max-width: $lg) {
  .root {
    max-width: 791px;
    padding: 0 30px;
  }
}

@media screen and (max-width: $md) {
  .root {
    max-width: 556px;
    padding: 0 30px;
  }
}

@media screen and (max-width: $mobile) {
  .root {
    padding: 0 20px;
    max-width: 100%;
  }
}
// @media screen and (max-width: $mdMinus){
//   .root{
//     max-width: 556px;
//     padding: 0 5px;
//   }
// }

// @media screen and (max-width: $sm){
//   .root{
//     padding: 0 15px;
//     width: 100%;
//   }
// }
