.root{
  display: inline-flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  text-transform: capitalize;

}


.active{
  cursor: pointer;

  &:hover{
    .svg{
      fill: #2C98B0;
    }
    .title:hover{
      color: #2C98B0
    }
  }
  &:active{
    .svg{
      fill: #000000;
    }
    .title:active{
      color: #000000;
    }
  }
}

.svg{
  fill: #09354F;
}

.disabled{
  opacity: .6;
}

.title{
  color: #09354F;
}
.info{
  color: #798E9E;
  margin-top: 6px;
  font-size: 10px;
}
.svg{
  margin: -5px 7px 0 0;
}