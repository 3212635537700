@import "src/components/scss/vars";
@import "src/components/scss/mixin";
.footer {
  position: relative;
  &:after {
    @include shadow("bottom", 0, "left", 0, 200px, 200px);
  }
}

.siteDevelop {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;

  a {
    margin-right: 5px;
    svg {
      path {
        fill: #000000;
      }
    }
  }
}
.siteDevelopText {
  display: block;
  font-size: 14px;
  line-height: 38px;
  margin-right: 12px;
  color: #09354f;
}
.svgSocial {
  svg {
    fill: #8897a0;
  }
}
.info {
  &:after {
    @include shadow("bottom", 0, "left", 0, 200px, 200px);
  }
  &__ulSocial {
    display: flex;
    align-items: center;

    li {
      margin-right: 7px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &Inner {
    padding: 35px 0 40px 0;
    display: flex;
    column-gap: 120px;
    align-items: flex-start;
  }
  &:after,
  &:before {
    display: block;
    content: "";
    opacity: 0.1;
    border: 0.5px solid #2c98b0;
  }
  &__logo {
    // width: 94px;
    height: 100%;
    max-width: 150px;
    img {
      width: 100%;
    }
  }
  &__title {
    margin-bottom: 3px;
    font-size: 16px;
    line-height: 171%;
    color: #09354f;
  }
  &__ul {
    max-width: 200px;
    font-weight: 300;
    font-size: 14px;
    line-height: 171%;
    color: #09354f;
  }
}
.contactFooterData_link {
  color: $lightDarkTextColor;
}
.wrapperInfoContact {
  span {
    margin-right: 5px;
  }
}
.donorsSvg {
  svg {
    width: 10px;
    height: 10px;
  }
}
.donors {
  // max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 24px 20px 60px;
  &__info {
    display: flex;
    justify-content: center;
    font-weight: 300;
    font-size: 10px;
    line-height: 145%;
    color: #09354f;
    & svg {
      margin-right: 5px;
    }
  }
  &__text {
    // white-space: nowrap;
    margin-top: 5px;
    font-weight: 300;
    font-size: 10px;
    color: #09354f;
    margin-right: 10px;
  }
}
.donorsArray {
  // max-width: 250px;
}
.donors__flags {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  & div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    // column-gap: 11px;
    // row-gap: 4px;
    // & svg {
    //   margin: 4px 11px;
    // }
  }
}
.svg {
  display: block;
  margin-bottom: 10px;
  margin-right: 11px;

  &:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: $md) {
  .donors__flags {
    align-items: flex-start;
  }
}
@media screen and (max-width: $mdMinus) {
  .donors__info {
    display: flex;
    flex-direction: column;
    svg {
      width: 22px;
      height: 22px;
    }
  }
  .donors__flags {
    flex-direction: column;
  }
  .donors__text {
    display: block;
    margin-bottom: 10px;
    margin-left: 11px;

    &:first-child {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: $smPlus) {
  .infoInnerWrapper {
    display: flex;
    justify-content: flex-start;
    padding: 0 10px;
  }

  .info__logo {
    display: none;
    max-width: 150px;
  }

  .description {
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .donors {
    padding: 24px 30px 60px;
  }
}

.description {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: 60px;
  // justify-content: space-between;
  justify-content: flex-end;
  & > * {
    margin: 10px 0;
  }
}
