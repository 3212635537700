@import "src/components/scss/vars";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 70px;
  color: $lightDarkTextColor;
  min-height: 500px;
  text-align: center;
}
.rootFontOne {
  font-family: "TragicMarker", sans-serif;
}

.rootFontTwo {
  font-family: "JakobCTT", sans-serif;
}
