@import "src/components/scss/vars";
.root {
  max-width: 370px;
  // width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 65px 44px;
  background: #ffffff;
  border: 1px solid #d8e9ed;
  border-radius: 10px;
  &:hover {
    box-shadow: 0 0 60px rgba(188, 229, 239, 0.5);
  }

  margin-bottom: 37px;
  &:last-child {
    margin-bottom: 0;
  }
}
.wrraperImage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  text-align: left;
}

.includes {
  max-width: 258px;
}
@media screen and (max-width: $lg) {
  .wrraperImage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title {
      text-align: left;
    }
  }
  .wrraperBtn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: $md) {
  .root {
    max-width: 100%;
    width: 100%;
  }
  .wrraperImage {
    width: 100%;
  }
  .includes {
    max-width: 100%;

    justify-content: flex-start;
  }
}

@media screen and (max-width: $smPlus) {
  .root {
    padding: 30px;
  }
  .wrraperImage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .title {
    text-align: left;
  }
}
