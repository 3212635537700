@import "src/components/scss/vars";

.finished {
  padding: 320px 0 174px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__info {
    margin: 23px 0 27px;
    max-width: 345px;
    text-align: center;
  }

  // overflow: hidden;
  &__img {
    position: absolute;
    z-index: -1;
    top: 180px;
    width: 100%;
  }
}

.underButtonLink {
  margin-top: 25px;
  font-size: 16px;
  line-height: 150.2%;
  display: flex;
  align-items: center;
  color: $darkBlackTextColor;
  img {
    margin-left: 5px;
  }
}
.section {
  background-image: url("../../../assets/img/Generals/finished.png");
  background-repeat: no-repeat;
  z-index: 10;
  background-position: center 20%;
  background-size: fill;
}
