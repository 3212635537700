.root {
  padding: 38px 32px;
  max-width: 577px;
  margin: auto;
  z-index: 10;
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
