.loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-animation: loader 2s ease alternate infinite;
  animation: loader 2s ease alternate infinite;

  img {
    width: 50%;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: scale(0.4) rotate(0deg);
    transform: scale(0.4) rotate(0deg);
  }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: scale(0.4) rotate(0deg);
    transform: scale(0.4) rotate(0deg);
  }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
