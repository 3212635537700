@import "src/components/scss/vars";
@import "src/components/scss/mixin";

.root {
  box-sizing: border-box;
  height: 144px;
  display: flex;
  align-items: center;
  position: relative;
  &:after {
    @include shadow("top", -100px, "left", -100px, 200px, 200px);
  }
  background: transparent;
  // background: rgba(0, 0, 0, 0);
}

.rootInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  width: 1280px;
  padding: 0 15px;
  margin: 0 auto;
}

.text {
  color: #0d5b7a;
}

nav.desktop {
  z-index: 5;
  & .dropdownText {
    display: flex;
    align-items: center;
  }
  & .menu {
    z-index: 5;
    display: flex;
    align-items: center;

    & .link {
      margin-left: 30px;
      display: block;
      font-size: 14px;
      color: #0d5b7a;
      text-align: center;

      position: relative;
      &.active {
        &:before {
          position: absolute;
          top: -65px;
          left: auto;

          content: "";
          display: block;
          height: 2px;
          width: 100%;
          background: #0d5b7a;
        }
      }

      & svg {
        margin-left: 3px;
      }
      & img {
        margin-left: 3px;
      }
    }

    & .btn {
      margin-left: 49px;
    }
  }
}

.line {
  opacity: 0.4;
  border: 0.7px solid #2c98b0;
  margin: 20px 0;
}

.normal {
  transition: 0.3s all ease;
  transform: rotate(0deg);
}

.arrow {
  width: 8px;
  height: 5px;
}

.reverse {
  transition: 0.3s all ease;
  transform: rotate(180deg);
}

.dropdownItems {
  display: block;
  position: absolute;
  z-index: 5;

  & .activedrop {
    background: #ecf8fb;
  }

  & .dropdownLink {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // height: 34px;
    padding-left: 5px;
    padding-right: 5px;
    padding: 10px 5px;

    &:hover {
      background: #ecf8fb;
    }
  }

  // & ul {
  //   padding: 15px 0;
  //   margin-left: -57px;
  //   width: 186px;
  //   min-height: 100px;
  //   // background: #ffffff;
  //   // box-shadow: 0 0 60px rgba(188, 229, 239, 0.5);
  //   border-radius: 10px;
  //   li {
  //     text-align: center;
  //   }
  //   :last-child {
  //     margin-left: 0;
  //     background: transparent;
  //     min-height: fit-content;
  //   }
  // }
}

.dropdown {
  padding: 15px 0;
  margin-left: -57px;
  width: 186px;
  // min-height: 100px;
  background: #ffffff;
  box-shadow: 0 0 60px rgba(188, 229, 239, 0.5);
  border-radius: 10px;
  li {
    text-align: left;
  }
}

.connect {
  height: 18px;
}

nav.mobile {
  display: none;
  z-index: 5;
  & .burger {
    cursor: pointer;

    & span {
      display: block;
      content: "";
      width: 18px;
      height: 3px;
      background: #09354f;
      border-radius: 10px;
      margin: 3px 0;
    }
  }

  & .close {
    cursor: pointer;
  }
}

.menuMobileTop {
  padding-top: 150px;
}

.menuAdaptive {
  overflow: auto;
  //padding-bottom: 50px;
  width: 100%;
  text-align: center;
  & li {
    width: 100%;
    color: #0d5b7a;
    font-size: 18px;
    padding: 12px 0;

    & > * {
      color: inherit;
    }
  }
}
.menuWrapper {
  // &:after {
  //   @include shadow("top", -100px, "left", -100px, 200px, 200px);
  // }
  // height: 100vh;
  position: absolute;
  z-index: 2;
  top: -150%;
  // left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  // transform: translateY(-150%);
  transition: all 0.3s ease 0s;
  & ul.menu {
    &:last-child:before {
      box-sizing: border-box;
      margin: 30px 0;
      display: block;
      content: "";
      width: 100%;
      opacity: 0.4;
      border: 0.7px solid #2c98b0;
    }
  }
}
.menuWrapperActive {
  // max-height: 100%;
  // transform: translateY(0%);
  // height: 100%;
  top: 0;
  // padding: 0 5px;
}
.menuWrapperBG {
  // overflow: scroll;
  // height: 100vh;
}
.adaptiveDropdown {
  cursor: pointer;
  text-align: center;

  & svg {
    margin-top: 3px;
    margin-left: 10px;
    width: 16px;
    height: 10px;
  }
}
.adaptiveDropdownItems {
  margin-top: 10px;
  background: #ecf8fb;

  & li {
    & > * {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: $lg) {
  .container {
    max-width: 924px;
    padding: 0 50px;
    width: 100%;
  }
  nav.desktop .menu .link {
    margin-left: 10px;

    font-size: 13px;
  }

  nav.desktop .menu .btn {
    margin-left: 25px;
  }
}

@media screen and (max-width: $md) {
  nav.desktop {
    display: none;
  }
  nav.mobile {
    display: block;
  }
  .btn {
    margin: 10px auto 50px;
  }
}

@media screen and (max-width: $mobile) {
  .container {
    padding: 0 15px;
  }
}

.logo {
  z-index: 5;
  display: block;
  max-width: 150px;
  img {
    max-width: 150px;
    width: 100%;
  }
}

.dropdownSubLinks {
  li {
    padding: 0;
    padding-top: 12px;
  }
}

.wrapperSublinkTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
